import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FRUIT from "../constants/fruit";
import FruitMenu from "./FruitMenu";
import INGREDIENTS from "../constants/ingredient";
import fruitColorMap from "../mappers/fruitColorMap";
import fruitStringMap from "../mappers/fruitStringMap";
import getStringFirstLetter from "../utils/getStringFirstLetter";
import ingredientStringMap from "../mappers/ingredientStringMap";
import { Button, CardActions, InputAdornment, OutlinedInput, Paper, Typography } from "@mui/material";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { getPercentage } from "../utils/getPercentage";
import { grey } from "@mui/material/colors";

export const Calculator = ({
  fruit,
  onFruitSelection,
}: {
  fruit: FRUIT;
  onFruitSelection: Dispatch<SetStateAction<FRUIT>>;
}) => {
  const BUTTON_TEXT = "Source Code";
  const [grams, setGrams] = useState<number>(0);
  const ingredients = Object.values(INGREDIENTS);

  return (
    <Card sx={{ minWidth: 400 }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: fruitColorMap[fruit] }}>{getStringFirstLetter(fruit)}</Avatar>}
        action={<FruitMenu onFruitSelection={onFruitSelection} />}
        title={`Super ${fruitStringMap[fruit]} Juice`}
      />
      <CardContent>
        <OutlinedInput
          endAdornment={<InputAdornment position="end">g</InputAdornment>}
          fullWidth={true}
          inputProps={{ min: "0" }}
          onChange={({ target: { value } }) => setGrams(parseInt(value, 10))}
          placeholder="Weight of peels"
          type="number"
          value={grams}
        />
        <br />
        <br />
        {ingredients.map((ingredient, i) => (
          <Fragment key={i}>
            <Paper key={i}>
              <Typography key={i}>{ingredientStringMap[ingredient]}</Typography>
              {displayGrams(getPercentage(grams, fruit, INGREDIENTS[ingredient]))}
            </Paper>
            <br />
          </Fragment>
        ))}
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
        <Button>
          <Typography color={grey[900]}>{BUTTON_TEXT}</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

const displayGrams = (value: number) => (
  <Typography fontSize={20} fontWeight={700}>
    {(!isNaN(value) ? value : 0).toFixed(2)} g
  </Typography>
);

export default Calculator;
