import FRUIT from "../constants/fruit";
import INGREDIENT from "../constants/ingredient";
import ingredientPercentageMap from "../mappers/ingredientPercentageMap";

export const getPercentage = (grams: number, fruit: FRUIT, ingredient: INGREDIENT) => {
  if (fruit === undefined || ingredient === undefined) {
    return 0;
  }
  const percentage = ingredientPercentageMap[fruit][ingredient] ? ingredientPercentageMap[fruit][ingredient] : 0;
  return (percentage / 100) * grams;
};

export default getPercentage;
