import FRUIT from "../constants/fruit";
import { green, orange, yellow } from "@mui/material/colors";

export const fruitColorMap: { [key in FRUIT]: string } = {
  [FRUIT.LEMON]: yellow[600],
  [FRUIT.LIME]: green[500],
  [FRUIT.ORANGE]: orange[500],
};

export default fruitColorMap;
