import Button from "@mui/material/Button";
import FRUIT from "../constants/fruit";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dispatch, SetStateAction, useState } from "react";
import getFruitEnum from "../utils/getFruitEnum";

export const FruitMenu = ({ onFruitSelection }: { onFruitSelection: Dispatch<SetStateAction<FRUIT>> }) => {
  const fruits = Object.values(FRUIT);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick}>
        <MoreVertIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {fruits.map((fruit, i) => (
          <MenuItem
            key={i}
            data-my-value={fruit}
            onClick={({
              currentTarget: {
                dataset: { myValue },
              },
            }) => {
              onFruitSelection(getFruitEnum(myValue ?? ""));
              handleClose();
            }}
          >
            {fruit}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FruitMenu;
