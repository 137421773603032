import "./App.css";
import Calculator from "./components/Calculator";
import FRUIT from "./constants/fruit";
import NavBar from "./components/NavBar";
import { useState } from "react";

const App = () => {
  const [fruit, setFruit] = useState<FRUIT>(FRUIT.LIME);
  return (
    <div className="App">
      <NavBar />
      <div className="background">
        <Calculator fruit={fruit} onFruitSelection={setFruit} />
      </div>
    </div>
  );
};

export default App;
