import FRUIT from "../constants/fruit";
import INGREDIENT from "../constants/ingredient";
import { IngredientPercentages } from "../types/ingredientPercentages";

export const ingredientPercentageMap: IngredientPercentages = {
  [FRUIT.LIME]: {
    [INGREDIENT.CITRIC_ACID]: 66.66,
    [INGREDIENT.MALIC_ACID]: 33.33,
    [INGREDIENT.WATER]: 1666.0,
  },
  [FRUIT.LEMON]: {
    [INGREDIENT.CITRIC_ACID]: 100.0,
    [INGREDIENT.MALIC_ACID]: 0.0,
    [INGREDIENT.WATER]: 1666.0,
  },
  [FRUIT.ORANGE]: {
    [INGREDIENT.CITRIC_ACID]: 90.0,
    [INGREDIENT.MALIC_ACID]: 10.0,
    [INGREDIENT.WATER]: 1666.0,
  },
};

export default ingredientPercentageMap;
